import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import '../styles/search.scss';

interface Props {
    searchDebounce?: number;
    onChangeSearchTerm: (searchTerm: string) => void;
}

const DialogSearch: React.FC<Props> = ({ onChangeSearchTerm, searchDebounce = 300 }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    const handleChange = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        debouncedChangeStateUpdate(searchTerm);
    };

    const onSaveSearchTerm = (searchTerm: string) => {
        onChangeSearchTerm(searchTerm);
    };

    // Only save the new searchterm after the user stopped typing for 300ms
    const debouncedChangeStateUpdate = useCallback(debounce(onSaveSearchTerm, searchDebounce), []);

    return (
        <div className="dialog-search">
            <OutlinedInput
                data-cy="genericDialog-search-input"
                placeholder={`${Translation.get('searchItems', 'bricks')}`}
                value={searchTerm}
                fullWidth
                onChange={(e) => handleChange(e.target.value)}
                startAdornment={
                    <InputAdornment position="start">
                        <Icon>search</Icon>
                    </InputAdornment>
                }
                endAdornment={
                    <React.Fragment>
                        {searchTerm.length > 0 && (
                            <InputAdornment style={{ cursor: 'pointer' }} onClick={() => handleChange('')} position="end">
                                <IconButton size="small">
                                    <Icon>clear</Icon>
                                </IconButton>
                            </InputAdornment>
                        )}
                    </React.Fragment>
                }
            />
        </div>
    );
};

export default DialogSearch;
