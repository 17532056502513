import React, { useEffect, useRef } from 'react';
import Slider, { SliderProps } from 'components/ui-components-v2/Slider';
import './styles/main.scss';

interface Props extends SliderProps {
    /** Callback function that will called before every repaint */
    onAnimationFrame?: () => void;
    /** Plays animation or stops animation frame request */
    playAnimation: boolean;
}

/**
 * This component is a wrapper around the MUI Slider component and uses the requestAnimationFrame function for animating the progress bar smoothly.
 * RequestAnimationFrame function ensures that the progress bar is animated at 60fps.
 * https://bycape.atlassian.net/wiki/spaces/DD/pages/187596806/ProgressBar
 */
const ProgressBar: React.FC<Props> = (props) => {
    const { onAnimationFrame, playAnimation } = props;
    const playAnimationRef = useRef(playAnimation);
    playAnimationRef.current = playAnimation; // Set playAnimationRef to the current value of playAnimation prop.

    /**
     * If playAnimation is true, the onAnimationFrame event will be triggered on every frame.
     * The requestAnimationFrame() method tells the browser you wish to perform an animation.
     * It requests the browser to call a user-supplied callback function before the next repaint.
     */
    const handleAnimationFrame = () => {
        if (!playAnimationRef.current) {
            return; // If playAnimation is false, stop animation frame request
        }

        onAnimationFrame && onAnimationFrame(); // Call onAnimationFrame callback function
        requestAnimationFrame(handleAnimationFrame); // Request animation frame
    };

    handleAnimationFrame();

    return <Slider className="ui-smooth-slider" {...props} />;
};

export default ProgressBar;
