import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DOMPurify from 'dompurify';
import Dialog from 'components/ui-components-v2/Dialog';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Button, { ButtonProps } from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import '../styles/main.scss';

interface Props {
    open: boolean;
    title: string;
    description: string | JSX.Element;
    waiting?: boolean;
    cancelText?: string;
    confirmText?: string;
    confirmColor?: ButtonProps['color'];
    cypressKey?: string;
    onConfirm?: (event?: any) => void;
    onClose: (event?: any) => void;
}

/**
 * Display a confirmation dialog
 * @param open Specify if dialog is open or closed
 * @param title Copy displayed in the title bar of the dialog
 * @param description Copy displayed in the body of the dialog
 * @param waiting Specify whether a spinner should be showed in the body of the dialog
 * @param confirmText Copy for the confirm button
 * @param confirmColor Color for the confirm button
 * @param onConfirm Action for the confirm button
 * @param onClose Action for closing the dialog without confirming
 */
const ConfirmDialog = ({
    open = false,
    title,
    description,
    waiting = false,
    cancelText = Translation.get('actions.cancel', 'common'),
    confirmText = Translation.get('actions.confirm', 'common'),
    confirmColor = 'primary',
    cypressKey,
    onConfirm,
    onClose
}: Props) => {
    return (
        <div className="confirm-dialog">
            <Dialog open={open} onClose={onClose} aria-labelledby="Confirm">
                <DialogTitle id="Confirm">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {waiting && <CircularProgress size={16} className="confirm-dialog__spinner" />}
                        {typeof description === 'string' ? <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} /> : description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={waiting} color="inherit">
                        {onConfirm ? cancelText : Translation.get('actions.ok', 'common')}
                    </Button>
                    {onConfirm && (
                        <Button
                            variant="contained"
                            data-cy={`ui-components-confirmDialog${cypressKey ? cypressKey : ''}Confirm-button`}
                            onClick={onConfirm}
                            color={confirmColor}
                            autoFocus
                            disabled={waiting}>
                            {confirmText}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfirmDialog;
