import React, { ReactNode } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Icon from 'components/ui-components-v2/Icon';
import { CustomOutlinedInputProps } from '.';

import '../styles/input.scss';

interface Props extends CustomOutlinedInputProps {
    className?: string;
    autoFocus?: boolean;
    query: string;
    searchPlaceholder?: string;
    formHelperText?: ReactNode;
    onHandleOnChange: (query: string) => void;
    onHandleCancelSearch: () => void;
    dataCy?: string;
}

const SearchFieldInput = ({
    autoFocus,
    query,
    searchPlaceholder,
    formHelperText,
    className,
    onHandleOnChange,
    onHandleCancelSearch,
    dataCy,
    ...outlinedInputProps
}: Props) => {
    return (
        <FormControl margin="none" fullWidth className={className}>
            <OutlinedInput
                inputProps={{ 'data-cy': dataCy }}
                className="search-field-input"
                autoFocus={autoFocus}
                id="search-field"
                value={query}
                fullWidth={outlinedInputProps.fullWidth}
                placeholder={searchPlaceholder}
                onChange={(e) => onHandleOnChange(e.target.value)}
                startAdornment={
                    <InputAdornment position="start">
                        <Icon className="search-field-input__search-icon" fontSize={outlinedInputProps.size}>
                            search
                        </Icon>
                    </InputAdornment>
                }
                endAdornment={
                    query &&
                    query.length > 0 && (
                        <InputAdornment onClick={onHandleCancelSearch} position="end">
                            <IconButton size="small">
                                <Icon fontSize={outlinedInputProps.size}>clear</Icon>
                            </IconButton>
                        </InputAdornment>
                    )
                }
                {...outlinedInputProps}
            />
            {formHelperText && <FormHelperText error={outlinedInputProps.error}>{formHelperText}</FormHelperText>}
        </FormControl>
    );
};

export default SearchFieldInput;
