import React, { useRef, useState } from 'react';
import { ListItemText, InputAdornment, IconButton, Box, ListItemIcon, Popover } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import axios from 'axios';
import MenuItem from 'components/ui-components-v2/MenuItem';
import MenuList from 'components/ui-components-v2/MenuList';
import Menu from 'components/ui-components-v2/Menu';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Icon from 'components/ui-components-v2/Icon';
import Divider from 'components/ui-components-v2/Divider';
import TextField from 'components/ui-components-v2/TextField';
import Setup from 'components/data/Setup';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import User from 'components/data/User';
import EditorData from 'components/editor-data/EditorData';
import Request from 'components/data/Request';
import './styles/main.scss';

type MultiLanguageValue = {
    [language: string]: {
        value: string;
        requiresTranslation?: boolean;
    };
};

interface LanguageAssistantProps {
    onMutation: (generatedText: unknown) => void;
    value: string | MultiLanguageValue;
    type: 'multiLanguage' | 'plain';
    language: string;
    maxLength?: number;
}

const LanguageAssistant = ({ onMutation, value, type, language = 'en', maxLength }: LanguageAssistantProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [promptInputValue, setPromptInputValue] = useState('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElPrompt, setAnchorElPrompt] = useState<HTMLElement | null>(null);
    const [anchorElWritingStyle, setAnchorElWritingStyle] = useState<HTMLElement | null>(null);
    const [anchorElLanguage, setAnchorElLanguage] = useState<HTMLElement | null>(null);
    const [canUseAI] = useState(Setup.hasModule('AI'));
    const translateItemRef = useRef(null);
    const writingStyleItemRef = useRef(null);
    const componentRef = useRef(null);

    /**
     * Action handlers
     * @param event
     */
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePromptOverlayToggle = () => {
        setAnchorEl(null);
        setAnchorElPrompt(componentRef.current);
    };

    const handleToggleWritingStylePopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElWritingStyle(anchorElWritingStyle ? null : event.currentTarget);
    };

    const handleToggleLanguagePopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElLanguage(anchorElLanguage ? null : event.currentTarget);
    };

    const handlePromptInputValueKeyDown = (event) => {
        if (event.key === 'Enter') {
            setAnchorElPrompt(null);
            handleGenericTextOperation('generate_based_on_text', promptInputValue);
        }
    };

    /**
     * Rendering the options for the language dropdown
     */
    const renderLanguageDropdownOptions = () => {
        const languages = Setup.getValueFromModel('languages');
        const languagesToDisplay: { language: string; name: string; value: string }[] = [];

        Object.keys(languages).forEach((languageItem) => {
            if (value[languageItem] && value[languageItem].value && languageItem !== language) {
                languagesToDisplay.push({ language: languageItem, name: languages[languageItem], value: value[languageItem].value });
            }
        });

        // Nothing to translate, skip button
        if (languagesToDisplay.length === 0) {
            return <span></span>;
        }

        // Setup up translate menu and popover with options
        return (
            <MenuItem ref={translateItemRef} onClick={handleToggleLanguagePopover}>
                <ListItemIcon>
                    <Icon>translate</Icon>
                </ListItemIcon>
                <ListItemText>{Translation.get('language-assistant.translation', 'common')}</ListItemText>
                <Icon color="disabled">keyboard_arrow_right</Icon>

                <Popover
                    open={Boolean(anchorElLanguage)}
                    anchorEl={anchorElLanguage}
                    onClose={() => setAnchorElLanguage(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}>
                    {languagesToDisplay.map((item, i) => (
                        <MenuItem key={i} onClick={() => handleAutoTranslate(item)}>
                            Autotranslate from {item.name}
                        </MenuItem>
                    ))}
                </Popover>
            </MenuItem>
        );
    };

    /**
     * Input change handlers
     */
    const getActiveValue = () => {
        if (!value) {
            return '';
        }
        // Multilanguage structure
        if (type === 'multiLanguage') {
            return value[language]?.value || '';
        }
        // Standard plain text
        if (type === 'plain') {
            return value;
        }
    };

    const setTextValue = (newValue: string) => {
        // Multilanguage structure
        if (type === 'multiLanguage' && typeof value === 'object') {
            const updatedValue = { ...value } as MultiLanguageValue;
            if (!updatedValue[language]) {
                updatedValue[language] = { value: newValue };
            } else {
                updatedValue[language].value = newValue;
            }

            onMutation(updatedValue);
        }
        // Standard plain text
        else if (type === 'plain') {
            onMutation(newValue);
        }
    };

    /**
     * Handles text generation or modification actions.
     * @param action - The text action function to execute.
     */
    const handleGenericTextOperation = async (action, promptText?: string, style?: string) => {
        const inputText = getActiveValue();

        setIsLoading(true);
        setAnchorEl(null);

        const data = {
            inputText: promptText ? promptText : inputText,
            action: action,
            creativity: 0.2,
            language: language,
            style: style || 'defaultStyleCode',
            maxLength
        };

        try {
            const response = await axios.post(process.env.APP_MEDIA_URL + 'media/textGenerator', data, {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }
            });

            if (response && response.data && response.data.text) {
                setTextValue(response.data.text);
                SnackbarUtils.success(Translation.get('language-assistant.generatedText', 'common'));
                return;
            }
        } catch (error) {
            console.log('Error in handleGenericTextOperation:', error);
            SnackbarUtils.error(Translation.get('language-assistant.generatedTextFailed', 'common'));
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Start auto translating
     */
    const handleAutoTranslate = async (selectedItem: { language: string; name: string; value: string }) => {
        const referenceValue = selectedItem.value;
        setIsLoading(true);
        setAnchorEl(null);

        // Load from API
        try {
            const data = await Request.post('translations/autoTranslate', {
                translate: { value: { referenceTranslation: referenceValue } },
                toLanguage: language.toLowerCase(),
                fromLanguage: selectedItem.language.toLowerCase()
            });
            const newValue = data?.data?.value?.value;
            if (newValue) {
                setTextValue(newValue);
            }
            setIsLoading(false);
        } catch (error) {
            SnackbarUtils.error(Translation.get('language-assistant.autoTranslateFailed', 'common'));
            setIsLoading(false);
        }
    };

    /**
     * When clicking the checkbox for the translation
     */
    const handleRequiresTranslationChange = (checked: boolean) => {
        const updatedValue = { ...(value as MultiLanguageValue) };
        updatedValue[language].requiresTranslation = checked;
        if (checked) {
            SnackbarUtils.success(Translation.get('language-assistant.selectFieldForTranslationDone', 'common'), 4000);
            EditorData.setEditorState('itemsToTranslate', EditorData.get('itemsToTranslate') ? EditorData.get('itemsToTranslate') + 1 : 1);
        } else {
            EditorData.setEditorState('itemsToTranslate', EditorData.get('itemsToTranslate') ? EditorData.get('itemsToTranslate') - 1 : 0);
        }
        onMutation(updatedValue);
        setAnchorEl(null);
    };

    return (
        <div className="language-assistant" ref={componentRef}>
            {!isLoading && canUseAI && (
                <div className="language-assistant__icon language-assistant__icon--ai" onClick={handleOpenMenu}>
                    <Icon>translate</Icon>
                </div>
            )}
            {!isLoading && !canUseAI && (
                <div className="language-assistant__icon" onClick={handleOpenMenu}>
                    <Icon>translate</Icon>
                </div>
            )}
            {isLoading && (
                <div className="language-assistant__icon">
                    <CircularProgress size={24} />
                </div>
            )}

            {anchorEl && (
                <Menu
                    className="inputField-assistant__menu"
                    anchorEl={anchorEl}
                    open={true}
                    onClose={() => setAnchorEl(null)}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}>
                    {canUseAI && (
                        <span>
                            <MenuItem onClick={handlePromptOverlayToggle}>
                                <ListItemIcon>
                                    <AutoAwesomeOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>{Translation.get('language-assistant.askAiToWrite', 'common')}</ListItemText>
                                <Icon color="disabled">keyboard_arrow_right</Icon>
                            </MenuItem>

                            {getActiveValue() && (
                                <span>
                                    <MenuItem className="writing-style" ref={writingStyleItemRef} onClick={handleToggleWritingStylePopover}>
                                        <ListItemIcon>
                                            <Icon>mic</Icon>
                                        </ListItemIcon>
                                        <ListItemText>{Translation.get('language-assistant.changeTone', 'common')}</ListItemText>
                                        <Icon color="disabled">keyboard_arrow_right</Icon>

                                        <Popover
                                            open={Boolean(anchorElWritingStyle)}
                                            anchorEl={anchorElWritingStyle}
                                            onClose={() => setAnchorElWritingStyle(null)}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}>
                                            <Box className="inputField-assistant__popover">
                                                <MenuItem onClick={() => handleGenericTextOperation('change_writing_style', undefined, 'Academic')}>
                                                    Academic
                                                </MenuItem>
                                                <MenuItem onClick={() => handleGenericTextOperation('change_writing_style', undefined, 'Technical')}>
                                                    Technical
                                                </MenuItem>
                                                <MenuItem onClick={() => handleGenericTextOperation('change_writing_style', undefined, 'Creative')}>
                                                    Creative
                                                </MenuItem>
                                                <MenuItem onClick={() => handleGenericTextOperation('change_writing_style', undefined, 'Business')}>
                                                    Business
                                                </MenuItem>
                                                <MenuItem onClick={() => handleGenericTextOperation('change_writing_style', undefined, 'Casual')}>
                                                    Casual
                                                </MenuItem>
                                            </Box>
                                        </Popover>
                                    </MenuItem>

                                    <MenuItem onClick={() => handleGenericTextOperation('make_shorter')}>
                                        <ListItemIcon>
                                            <Icon>short_text</Icon>
                                        </ListItemIcon>
                                        <ListItemText>{Translation.get('language-assistant.makeShorter', 'common')}</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => handleGenericTextOperation('make_longer')}>
                                        <ListItemIcon>
                                            <Icon>notes</Icon>
                                        </ListItemIcon>
                                        <ListItemText>{Translation.get('language-assistant.makeLonger', 'common')}</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => handleGenericTextOperation('fix_grammar_spelling')}>
                                        <ListItemIcon>
                                            <Icon>healing</Icon>
                                        </ListItemIcon>

                                        <ListItemText>{Translation.get('language-assistant.grammarCheck', 'common')}</ListItemText>
                                    </MenuItem>
                                </span>
                            )}
                            {type === 'multiLanguage' && <Divider />}
                        </span>
                    )}

                    {type === 'multiLanguage' && (
                        <span>
                            <MenuItem onClick={() => handleRequiresTranslationChange(value[language]?.requiresTranslation ? false : true)}>
                                <ListItemIcon>{value[language]?.requiresTranslation ? <Icon>check</Icon> : <Icon>translate</Icon>}</ListItemIcon>
                                <ListItemText>
                                    {value[language]?.requiresTranslation
                                        ? Translation.get('language-assistant.unselectFieldForTranslation', 'common')
                                        : Translation.get('language-assistant.selectFieldForTranslation', 'common')}
                                </ListItemText>
                            </MenuItem>

                            {renderLanguageDropdownOptions()}
                        </span>
                    )}
                </Menu>
            )}

            {anchorElPrompt && (
                <Popover
                    className="language-assistant__text-field-container"
                    open={true}
                    anchorEl={anchorElPrompt}
                    onClose={() => setAnchorElPrompt(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}>
                    {!isLoading && (
                        <Box className="language-assistant__border-wrap">
                            <TextField
                                multiline
                                placeholder={Translation.get('language-assistant.writeText', 'common')}
                                variant="outlined"
                                value={promptInputValue}
                                onChange={(e) => setPromptInputValue(e.target.value)}
                                onKeyDown={handlePromptInputValueKeyDown}
                                className="language-assistant__input-subfield"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" style={{ marginRight: 4 }}>
                                            <Icon className="language-assistant__star-icon">auto_awesome</Icon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    handleGenericTextOperation('generate_based_on_text', promptInputValue);
                                                    setAnchorElPrompt(null);
                                                }}
                                                className="language-assistant__arrow-up"
                                                disabled={!promptInputValue}>
                                                <Icon className="language-assistant__arrow-up-icon">arrow_forward</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                    )}
                </Popover>
            )}
        </div>
    );
};
export default LanguageAssistant;
